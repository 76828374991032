import ProfessionalDetailRootPage from '~/modules/professional/page/ProfessionalPage';
import { loader } from '~/modules/professional/page/loaders/professional-page-loader.server';

import {
    createOGTags,
    fallbackOGSocialImage,
    getProfessionalBannerByPriority,
    getCanonicalHreflang,
    mergeMeta,
} from '@modules/seo';
import { createLDSchema } from '~/modules/seo/util/create-ld-schema';
import { getProfessionalPageTitle } from '~/modules/seo/util/get-professional-page-title';
import { getProfessionalPageMetaDescription } from '~/modules/seo/util/get-professional-page-meta-description';
import { createProfessionalSchema } from '~/modules/seo/schema/professional-schema';
import { createFAQSchema } from '~/modules/seo/schema/faq-page-schema';
import { noIndexCrossCountry } from '~/modules/seo/util/no-index-cross-country';
import { getRouteData } from '~/modules/root/util/get-route-data';
import type { APHandle } from '~/modules/root';

export const meta = mergeMeta<typeof loader>(
    (_props) => {
        return [];
    },
    (props) => {
        const { data, matches } = props;
        if (!data?.initial) {
            return [];
        }
        const { rootData } = getRouteData(matches);

        const title = getProfessionalPageTitle(props, data.initial?.Title);
        const description = getProfessionalPageMetaDescription(
            props,
            [
                data?.localisation?.Description,
                data?.localisation?.DirectoryHeader?.Content,
            ],
            data.initial?.Title
        );

        const fallbackSocialImage = fallbackOGSocialImage(
            props,
            getProfessionalBannerByPriority(props)
        );

        const ldSchemaElements = [
            createFAQSchema(data.localisation?.FAQSchemas ?? []),
        ];

        if (data.shouldIncludeLocalBusinessSchema) {
            ldSchemaElements.push(createProfessionalSchema(props));
        }

        return [
            { title },
            { name: 'description', content: description },
            ...createOGTags({
                meta: props,
                title,
                description,
                facebookSocialImage:
                    data?.initial?.FacebookPreviewImage ?? fallbackSocialImage,
                type: 'website',
            }),
            ...getCanonicalHreflang(props, {
                professionalCountry: data.initial.Country,
                noAlternateLinks: true,
            }),
            ...createLDSchema(ldSchemaElements),
            ...noIndexCrossCountry(
                data?.initial?.Country,
                rootData?.localisation?.country,
                data?.localisation
            ),
        ];
    }
);

export const handle: APHandle = {
    fadeTopNavAfterPosition: 200,
    isProfessionalProfile: true,
};

export { loader };
export default ProfessionalDetailRootPage;
