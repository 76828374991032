import { DetailsPageIsland } from '@rocco/ui/floating-island/components/detailspage-island/DetailsPageIsland';
import { useProfessionalInitialRouteData } from '../../hook/use-professional-initial-route-data';
import { ReactComponent as EnquiryIcon } from '@rocco/icons/svg/message.svg';
import { useHandleFollow } from '~/modules-rocco/design-board/hook/use-handle-follow';
import { BookmarkIcon } from 'lucide-react';
import { cn } from '@rocco/utils/cn';
import { useRootData } from '~/modules/root';
import { Button } from '@rocco/components/button';
import { DisplayMode } from '@rocco/types/enum/display-mode';
import { trackEnquireReveal } from '~/modules/tracking/util/trackEnquireReveal';
import { useTracker } from '@archipro-website/tracker';

interface ProfessionalIslandProps {
    openEnquiryModal: (changeUrl?: boolean, customHeading?: string) => void;
}

export const ProfessionalIsland = ({
    openEnquiryModal,
}: ProfessionalIslandProps) => {
    const {
        CompanyLogo: companyLogo,
        LogoBackgroundColor: logoBackgroundColor,
        ID: professionalId,
        Title: professionalName,
        Categories,
    } = useProfessionalInitialRouteData();

    const { appDisplayMode } = useRootData();

    const tracker = useTracker();

    const onEnquiryClick = (label: string) => {
        trackEnquireReveal(tracker, {
            professionalName,
            itemID: professionalId,
            itemType: 'professional',
            source: 'ProfessionalIsland',
            ctaLabel: label,
        });

        openEnquiryModal(false, label);
    };

    return (
        <DetailsPageIsland
            key={`professional-island-${professionalId}`}
            displayMode={appDisplayMode}
            scrollThreshold={0}
            image={{
                image: {
                    src: companyLogo,
                    alt: professionalName,
                },
                background: logoBackgroundColor,
                size: 'professionalLogo',
            }}
            title={professionalName}
            description={Categories[0]?.PrettyTitle ?? ''}
            primaryAction={
                <ProfessionalIslandEnquiryButton
                    label={'Send an Enquiry'}
                    onClick={() => onEnquiryClick('Send an Enquiry')}
                    displayMode={appDisplayMode}
                />
            }
            secondaryAction={
                <ProfessionalIslandAddToDesignBoardButton
                    label="Shortlist this Pro"
                    displayMode={appDisplayMode}
                    professionalId={professionalId}
                />
            }
        />
    );
};

const ProfessionalIslandEnquiryButton = ({
    label,
    onClick,
    displayMode,
}: {
    label: string;
    onClick: () => void;
    displayMode: DisplayMode;
}) => {
    const compact = displayMode === DisplayMode.Mobile;

    return (
        <Button
            type="button"
            size={compact ? 'square42' : 'default'}
            color="primary"
            onClick={onClick}
        >
            <span className="flex items-center gap-2">
                {compact ? null : label}
                <EnquiryIcon aria-label={label} className="w-4 fill-black" />
            </span>
        </Button>
    );
};

const ProfessionalIslandAddToDesignBoardButton = ({
    label,
    displayMode,
    professionalId,
}: {
    label: string;
    displayMode: DisplayMode;
    professionalId: number;
}) => {
    const compact = displayMode === DisplayMode.Mobile;
    const { isInDesignBoard, handleFollow } = useHandleFollow();
    const saved = isInDesignBoard(professionalId, 'ProfessionalPin');
    return (
        <Button
            type="button"
            size={compact ? 'square42' : 'default'}
            color="secondary70"
            onClick={() =>
                handleFollow({ id: professionalId }, 'ProfessionalPin')
            }
        >
            <span className="flex items-center gap-2">
                {compact ? null : label}
                <BookmarkIcon
                    aria-label={label}
                    className={cn(
                        'w-4',
                        saved ? 'fill-gray-900' : 'stroke-gray-900'
                    )}
                />
            </span>
        </Button>
    );
};
