import { Outlet, useLoaderData } from '@remix-run/react';
import { useSetAtom } from 'jotai';
import { useEffect } from 'react';
import PageLayout from '@modules/professional/component/page-layout/PageLayout';
import type { DetailPageButtonsProps } from '@modules/business-manager/component/detail-page-buttons/DetailPageButtons';
import { DetailPageButtons } from '@modules/business-manager/component/detail-page-buttons/DetailPageButtons';
import type { ProfessionalDetailsInitialFragment } from 'generated/graphql';
import type { Branch } from '~/modules/enquiry/util/extract-branch-from-network.server';
import type { Breadcrumb } from '@modules/professional/type/details-root.types';
import type { DropdownItem } from '@modules/enquiry/component/enquiry-form/EnquiryForm';
import { useInView } from 'react-intersection-observer';
import { useSetEditMode } from '~/modules/root/hook/use-set-edit-mode';
import type { PrimaryCTAResult } from '@modules/professional/util/professional-cta-helper';
import { getBMPagePath } from '@modules/root/util/get-bm-page-path';
import type { ProfessionalBannersData } from '@modules/professional/page/OverviewPage';
import type { loader } from '@modules/professional/page/loaders/professional-page-loader.server';
import type { getPageLocalisation } from '~/modules/seo/api/get-page-localisation.server';
import { useEnquiryModalByUrl } from '~/modules/enquiry/hook/use-enquiry-modal-by-url';
import { ProfessionalIsland } from '../component/professional-island/ProfessionalIsland';
import { useAppDisplayModeContext } from '@archipro-design/aria';
import { setCloneCountryStateAtom } from '~/state/ui-state-clone-country-link';

export interface ProfessionalDetailRootData {
    initial: ProfessionalDetailsInitialFragment;
    professionalBreadcrumb: Breadcrumb[];
    banners: ProfessionalBannersData;
    branchOptions: DropdownItem<Branch>[];
    detailButton: Omit<DetailPageButtonsProps, 'editLink'>;
    localisation: Awaited<ReturnType<typeof getPageLocalisation>>;
    primaryCTA: PrimaryCTAResult;
    shouldIncludeLocalBusinessSchema: boolean;
}

export type ProfessionalDetailRootLoaderData = typeof loader;

export interface ProfessionalOutletContext {
    openEnquiryModal: (changeUrl?: boolean) => void;
}

const ProfessionalDetailRootPage = () => {
    const { initial, detailButton, primaryCTA, awards } =
        useLoaderData<ProfessionalDetailRootLoaderData>();
    const editLink = getBMPagePath(initial.URLSegment);
    useSetEditMode(editLink, initial.ID);
    const [footerSectionRef, footerSectionInView] = useInView({
        threshold: 0.5,
    });
    const { desktop } = useAppDisplayModeContext();
    const { renderEnquiryModal, openEnquiryModal } = useEnquiryModalByUrl();

    const setCountryData = useSetAtom(setCloneCountryStateAtom);
    // Set the atom value when the data loads
    useEffect(() => {
        setCountryData({
            urlSegment: initial?.URLSegment,
            currentCountry: initial?.Country,
            pageType: initial?.PageType,
            associatedPages: initial?.AssociatedPages,
        });
    }, [initial, setCountryData]);

    return (
        <PageLayout
            primaryCTA={primaryCTA}
            awards={awards}
            openEnquiryModal={openEnquiryModal}
        >
            <DetailPageButtons
                showButton={!footerSectionInView}
                {...detailButton}
                editLink={editLink}
            />
            <Outlet context={{ openEnquiryModal }} />
            {renderEnquiryModal({
                professional: {
                    ...initial,
                    CustomEnquiryMessage: initial.CustomEnquiryMessage ?? '',
                    Website: initial.Website ?? '',
                },
            })}
            {desktop && (
                <ProfessionalIsland openEnquiryModal={openEnquiryModal} />
            )}
            <div ref={footerSectionRef} />
        </PageLayout>
    );
};

export default ProfessionalDetailRootPage;
